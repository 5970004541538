import { inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, map } from 'rxjs';
import { appRoutesNames } from 'src/app/routes/app.routes.names';
import { authRoutesNames } from 'src/app/routes/auth/auth.routes.names';
import { AuthService } from '../services';

export const authGuard: CanMatchFn = (
  _route: Route,
  urlSegments: UrlSegment[],
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.isAuthenticated$().pipe(
    map((isAuthenticated) => {
      if (isAuthenticated) {
        return true;
      }
      const redirectUrl = urlSegments.map((segment) => segment.path).join('/');
      return router.createUrlTree(
        [appRoutesNames.AUTH, authRoutesNames.LOGIN],
        {
          queryParams: { redirect: `/${redirectUrl}` },
          queryParamsHandling: 'merge',
        },
      );
    }),
  );
};
