/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConnectGroupDto } from './connectGroupDto';

export interface UpdateGroupParentGroupRelationInputDto {
  connect?: ConnectGroupDto | null;
  disconnect?: boolean | null;
}
