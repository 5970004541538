/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentQuestionDetailsDto } from './assessmentQuestionDetailsDto';
import { Link } from './link';
import { TranslationsDto } from './translationsDto';

export interface AssessmentCategoryDetailsDto {
  uuid?: string;
  tenantUuid: string;
  userUuid: string;
  assessmentUuid: string;
  assessmentStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireCategoryName?: TranslationsDto;
  questionnaireCategoryOrder?: number;
  questionnaireCategoryRelevance?: number;
  questionnaireCategoryWeight?: number;
  score?: number;
  progress?: number;
  questions?: Array<AssessmentQuestionDetailsDto>;
  links?: Array<Link>;
}
