/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';
import { QuestionnaireStatisticsAnswerDto } from './questionnaireStatisticsAnswerDto';
import { TranslationsDto } from './translationsDto';

export interface QuestionnaireStatisticsStatementDto {
  uuid?: string;
  tenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireQuestionUuid: string;
  statement?: TranslationsDto;
  order?: number;
  weight?: number;
  responseCount?: number;
  answers?: Array<QuestionnaireStatisticsAnswerDto>;
  links?: Array<Link>;
}
