/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BlogDto } from '../model/blogDto';
// @ts-ignore
import { CreateBlogDto } from '../model/createBlogDto';
// @ts-ignore
import { GetBlogs200Response } from '../model/getBlogs200Response';
// @ts-ignore
import { UpdateBlogDto } from '../model/updateBlogDto';

// @ts-ignore
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

export interface CreateBlogRequestParams {
  xTenantSlug: string;
  createBlogDto: CreateBlogDto;
}

export interface DeleteBlogRequestParams {
  xTenantSlug: string;
  blogUuid: string;
}

export interface GetBlogRequestParams {
  xTenantSlug: string;
  blogUuid: string;
}

export interface GetBlogsRequestParams {
  xTenantSlug: string;
  page?: number;
  size?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: 'createdAt' | 'name';
  name?: string;
}

export interface UpdateBlogRequestParams {
  xTenantSlug: string;
  blogUuid: string;
  updateBlogDto: UpdateBlogDto;
}

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  protected basePath = 'http://localhost:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10),
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createBlog(
    requestParameters: CreateBlogRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BlogDto>;
  public createBlog(
    requestParameters: CreateBlogRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BlogDto>>;
  public createBlog(
    requestParameters: CreateBlogRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BlogDto>>;
  public createBlog(
    requestParameters: CreateBlogRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling createBlog.',
      );
    }
    const createBlogDto = requestParameters?.createBlogDto;
    if (createBlogDto === null || createBlogDto === undefined) {
      throw new Error(
        'Required parameter createBlogDto was null or undefined when calling createBlog.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/blogs`;
    return this.httpClient.request<BlogDto>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: createBlogDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteBlog(
    requestParameters: DeleteBlogRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BlogDto>;
  public deleteBlog(
    requestParameters: DeleteBlogRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BlogDto>>;
  public deleteBlog(
    requestParameters: DeleteBlogRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BlogDto>>;
  public deleteBlog(
    requestParameters: DeleteBlogRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling deleteBlog.',
      );
    }
    const blogUuid = requestParameters?.blogUuid;
    if (blogUuid === null || blogUuid === undefined) {
      throw new Error(
        'Required parameter blogUuid was null or undefined when calling deleteBlog.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/blogs/${this.configuration.encodeParam({ name: 'blogUuid', value: blogUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<BlogDto>(
      'delete',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlog(
    requestParameters: GetBlogRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BlogDto>;
  public getBlog(
    requestParameters: GetBlogRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BlogDto>>;
  public getBlog(
    requestParameters: GetBlogRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BlogDto>>;
  public getBlog(
    requestParameters: GetBlogRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getBlog.',
      );
    }
    const blogUuid = requestParameters?.blogUuid;
    if (blogUuid === null || blogUuid === undefined) {
      throw new Error(
        'Required parameter blogUuid was null or undefined when calling getBlog.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/blogs/${this.configuration.encodeParam({ name: 'blogUuid', value: blogUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<BlogDto>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlogs(
    requestParameters: GetBlogsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GetBlogs200Response>;
  public getBlogs(
    requestParameters: GetBlogsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GetBlogs200Response>>;
  public getBlogs(
    requestParameters: GetBlogsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GetBlogs200Response>>;
  public getBlogs(
    requestParameters: GetBlogsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getBlogs.',
      );
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const sortOrder = requestParameters?.sortOrder;
    const sortBy = requestParameters?.sortBy;
    const name = requestParameters?.name;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page',
      );
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>size,
        'size',
      );
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortOrder,
        'sortOrder',
      );
    }
    if (sortBy !== undefined && sortBy !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortBy,
        'sortBy',
      );
    }
    if (name !== undefined && name !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>name,
        'name',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/blogs`;
    return this.httpClient.request<GetBlogs200Response>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBlog(
    requestParameters: UpdateBlogRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BlogDto>;
  public updateBlog(
    requestParameters: UpdateBlogRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BlogDto>>;
  public updateBlog(
    requestParameters: UpdateBlogRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BlogDto>>;
  public updateBlog(
    requestParameters: UpdateBlogRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling updateBlog.',
      );
    }
    const blogUuid = requestParameters?.blogUuid;
    if (blogUuid === null || blogUuid === undefined) {
      throw new Error(
        'Required parameter blogUuid was null or undefined when calling updateBlog.',
      );
    }
    const updateBlogDto = requestParameters?.updateBlogDto;
    if (updateBlogDto === null || updateBlogDto === undefined) {
      throw new Error(
        'Required parameter updateBlogDto was null or undefined when calling updateBlog.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/blogs/${this.configuration.encodeParam({ name: 'blogUuid', value: blogUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<BlogDto>(
      'patch',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: updateBlogDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }
}
