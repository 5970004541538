/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';
import { TranslationsDto } from './translationsDto';

export interface QuestionnaireStageDto {
  uuid?: string;
  tenantUuid: string;
  questionnaireUuid: string;
  parentQuestionnaireStageUuid?: string;
  name?: TranslationsDto;
  order?: number;
  weight?: number;
  links?: Array<Link>;
}
