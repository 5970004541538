/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BinaryRecordDto } from '../model/binaryRecordDto';
// @ts-ignore
import { BinaryRecordsMetaDto } from '../model/binaryRecordsMetaDto';

// @ts-ignore
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

export interface CreateBinaryRequestParams {
  xTenantSlug: string;
  file?: Blob;
}

export interface DeleteBinaryRequestParams {
  xTenantSlug: string;
  binaryRecordUuid: string;
}

export interface GetBinaryRequestParams {
  xTenantSlug: string;
  binaryRecordUuid: string;
  version?: number;
}

export interface GetBinaryRecordsMetaRequestParams {
  xTenantSlug: string;
  binaryRecordUuid: string;
}

export interface UpdateBinaryRequestParams {
  xTenantSlug: string;
  binaryRecordUuid: string;
  file?: Blob;
}

@Injectable({
  providedIn: 'root',
})
export class BinariesService {
  protected basePath = 'http://localhost:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10),
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createBinary(
    requestParameters: CreateBinaryRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BinaryRecordDto>;
  public createBinary(
    requestParameters: CreateBinaryRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BinaryRecordDto>>;
  public createBinary(
    requestParameters: CreateBinaryRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BinaryRecordDto>>;
  public createBinary(
    requestParameters: CreateBinaryRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling createBinary.',
      );
    }
    const file = requestParameters?.file;

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({ encoder: this.encoder });
    }

    if (file !== undefined) {
      localVarFormParams =
        (localVarFormParams.append('file', <any>file) as any) ||
        localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/binaries`;
    return this.httpClient.request<BinaryRecordDto>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: localVarConvertFormParamsToString
          ? localVarFormParams.toString()
          : localVarFormParams,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteBinary(
    requestParameters: DeleteBinaryRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any>;
  public deleteBinary(
    requestParameters: DeleteBinaryRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<any>>;
  public deleteBinary(
    requestParameters: DeleteBinaryRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<any>>;
  public deleteBinary(
    requestParameters: DeleteBinaryRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling deleteBinary.',
      );
    }
    const binaryRecordUuid = requestParameters?.binaryRecordUuid;
    if (binaryRecordUuid === null || binaryRecordUuid === undefined) {
      throw new Error(
        'Required parameter binaryRecordUuid was null or undefined when calling deleteBinary.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/binaries/${this.configuration.encodeParam({ name: 'binaryRecordUuid', value: binaryRecordUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<any>(
      'delete',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBinary(
    requestParameters: GetBinaryRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any>;
  public getBinary(
    requestParameters: GetBinaryRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<any>>;
  public getBinary(
    requestParameters: GetBinaryRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<any>>;
  public getBinary(
    requestParameters: GetBinaryRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: undefined;
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getBinary.',
      );
    }
    const binaryRecordUuid = requestParameters?.binaryRecordUuid;
    if (binaryRecordUuid === null || binaryRecordUuid === undefined) {
      throw new Error(
        'Required parameter binaryRecordUuid was null or undefined when calling getBinary.',
      );
    }
    const version = requestParameters?.version;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (version !== undefined && version !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>version,
        'version',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/binaries/${this.configuration.encodeParam({ name: 'binaryRecordUuid', value: binaryRecordUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<any>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBinaryRecordsMeta(
    requestParameters: GetBinaryRecordsMetaRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BinaryRecordsMetaDto>;
  public getBinaryRecordsMeta(
    requestParameters: GetBinaryRecordsMetaRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BinaryRecordsMetaDto>>;
  public getBinaryRecordsMeta(
    requestParameters: GetBinaryRecordsMetaRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BinaryRecordsMetaDto>>;
  public getBinaryRecordsMeta(
    requestParameters: GetBinaryRecordsMetaRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getBinaryRecordsMeta.',
      );
    }
    const binaryRecordUuid = requestParameters?.binaryRecordUuid;
    if (binaryRecordUuid === null || binaryRecordUuid === undefined) {
      throw new Error(
        'Required parameter binaryRecordUuid was null or undefined when calling getBinaryRecordsMeta.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/binaries/${this.configuration.encodeParam({ name: 'binaryRecordUuid', value: binaryRecordUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/meta`;
    return this.httpClient.request<BinaryRecordsMetaDto>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateBinary(
    requestParameters: UpdateBinaryRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<BinaryRecordDto>;
  public updateBinary(
    requestParameters: UpdateBinaryRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<BinaryRecordDto>>;
  public updateBinary(
    requestParameters: UpdateBinaryRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<BinaryRecordDto>>;
  public updateBinary(
    requestParameters: UpdateBinaryRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling updateBinary.',
      );
    }
    const binaryRecordUuid = requestParameters?.binaryRecordUuid;
    if (binaryRecordUuid === null || binaryRecordUuid === undefined) {
      throw new Error(
        'Required parameter binaryRecordUuid was null or undefined when calling updateBinary.',
      );
    }
    const file = requestParameters?.file;

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let localVarFormParams: { append(param: string, value: any): any };
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({ encoder: this.encoder });
    }

    if (file !== undefined) {
      localVarFormParams =
        (localVarFormParams.append('file', <any>file) as any) ||
        localVarFormParams;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/binaries/${this.configuration.encodeParam({ name: 'binaryRecordUuid', value: binaryRecordUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<BinaryRecordDto>(
      'patch',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: localVarConvertFormParamsToString
          ? localVarFormParams.toString()
          : localVarFormParams,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }
}
