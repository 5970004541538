export * from './administrationTenants.service';
export * from './authentication.service';
export * from './binaries.service';
export * from './blogEntries.service';
export * from './blogs.service';
export * from './groups.service';
export * from './userProfile.service';
export * from './users.service';
import { AdministrationTenantsService } from './administrationTenants.service';
import { AuthenticationService } from './authentication.service';
import { BinariesService } from './binaries.service';
import { BlogEntriesService } from './blogEntries.service';
import { BlogsService } from './blogs.service';
import { GroupsService } from './groups.service';
import { UserProfileService } from './userProfile.service';
import { UsersService } from './users.service';
export const APIS = [
  AdministrationTenantsService,
  AuthenticationService,
  BinariesService,
  BlogEntriesService,
  BlogsService,
  GroupsService,
  UserProfileService,
  UsersService,
];
