/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentQuestionDto } from './assessmentQuestionDto';
import { Link } from './link';
import { PageMetadata } from './pageMetadata';

export interface PagedModelAssessmentQuestionDto {
  links?: Array<Link>;
  content?: Array<AssessmentQuestionDto>;
  page?: PageMetadata;
}
