/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentBenchmarkStageDto } from './assessmentBenchmarkStageDto';
import { Link } from './link';
import { TranslationsDto } from './translationsDto';

export interface EntityModelAssessmentBenchmarkDto {
  tenantUuid: string;
  userUuid: string;
  questionnaireUuid: string;
  assessmentUuid: string;
  name?: TranslationsDto;
  questionnaireAverageScore?: number;
  assessmentScore?: number;
  stages?: Array<AssessmentBenchmarkStageDto>;
  links?: Array<Link>;
}
