/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Link {
  rel?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  title?: string;
  type?: string;
  deprecation?: string;
  profile?: string;
  name?: string;
}
