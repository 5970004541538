/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BinaryRecordDto {
  uuid: string;
  version: number;
  createdAt: string;
  createdByUserUuid: string | null;
  tenantUuid: string;
  fileName: string;
  fileExtension: string | null;
  mimeType: string;
  size: number;
}
