/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';
import { TranslationsDto } from './translationsDto';

export interface AssessmentAnswerDetailsDto {
  uuid?: string;
  tenantUuid: string;
  userUuid: string;
  assessmentUuid: string;
  assessmentStageUuid: string;
  assessmentCategoryUuid: string;
  assessmentQuestionUuid: string;
  assessmentStatementUuid?: string;
  questionnaireAnswerUuid: string;
  questionnaireAnswerAnswer?: TranslationsDto;
  questionnaireAnswerRecommendation?: TranslationsDto;
  questionnaireStatementAnswerUuid?: string;
  score?: number;
  links?: Array<Link>;
  questionnaireAnswerExcludeQuestionFromScoring?: boolean;
  questionnaireStatementAnswerExcludeStatementFromScoring?: boolean;
}
