/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserWithTenantDto } from './userWithTenantDto';

export interface TokenResponseDto {
  accessToken: string;
  refreshToken: string;
  user: UserWithTenantDto;
}
