/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConnectUserDto } from './connectUserDto';

export interface UpdateGroupUsersRelationInputDto {
  connect?: Array<ConnectUserDto> | null;
  disconnect?: ConnectUserDto | null;
}
