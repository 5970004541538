/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';
import { TranslationsDto } from './translationsDto';

export interface AssessmentQuestionDto {
  uuid?: string;
  tenantUuid: string;
  userUuid: string;
  assessmentUuid: string;
  assessmentStageUuid: string;
  assessmentCategoryUuid: string;
  questionnaireQuestionUuid: string;
  questionnaireQuestionType: AssessmentQuestionDto.QuestionnaireQuestionTypeEnum;
  questionnaireQuestionQuestion?: TranslationsDto;
  questionnaireQuestionDescription?: TranslationsDto;
  questionnaireQuestionOrder?: number;
  questionnaireQuestionWeight?: number;
  score?: number;
  progress?: number;
  links?: Array<Link>;
  questionnaireQuestionNormalizeStatementWeights?: boolean;
}
export namespace AssessmentQuestionDto {
  export type QuestionnaireQuestionTypeEnum =
    | 'SINGLE_CHOICE'
    | 'MULTIPLE_CHOICE'
    | 'LICKERT_SCALE';
  export const QuestionnaireQuestionTypeEnum = {
    SingleChoice: 'SINGLE_CHOICE' as QuestionnaireQuestionTypeEnum,
    MultipleChoice: 'MULTIPLE_CHOICE' as QuestionnaireQuestionTypeEnum,
    LickertScale: 'LICKERT_SCALE' as QuestionnaireQuestionTypeEnum,
  };
}
