import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private platformId = inject(PLATFORM_ID);

  availableLanguages = [...this.translocoService.getAvailableLangs()];

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    private readonly route: ActivatedRoute,
    private readonly logger: NGXLogger,
    private router: Router,
  ) {
    this.setLanguage(this.getLanguage());
    this.route.queryParamMap.subscribe((params) => {
      this.setUrlParameterLanguage(params.get('lang') ?? undefined);
    });
  }

  setLanguage(language: string) {
    this.localStorageService.setLanguage(language);
    this.translocoService.setActiveLang(language);
  }

  private setUrlParameterLanguage(language: string | undefined) {
    if (language && this.availableLanguages.includes(language)) {
      this.logger.debug(
        'LanguageService - Language from url parameter:',
        language,
      );
      this.setLanguage(language);
      this.router
        .navigate([], {
          relativeTo: this.route,
          queryParams: { lang: null },
          queryParamsHandling: 'merge',
        })
        .then();
    }
  }

  private getBrowserLanguage() {
    if (!isPlatformBrowser(this.platformId)) {
      return undefined;
    }
    const language = navigator.language.substring(0, 2);
    this.logger.debug(
      'LanguageService - Identified browser language:',
      language,
    );
    return this.availableLanguages.includes(language) ? language : undefined;
  }

  getLanguage() {
    return (
      this.localStorageService.getLanguage() ||
      this.authService.currentUserValue?.lang ||
      this.getBrowserLanguage() ||
      this.translocoService.getDefaultLang()
    );
  }
}
