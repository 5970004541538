/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDto } from './userDto';

export interface BlogEntryDto {
  uuid: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  published: boolean;
  blogUuid: string;
  authorUserUuid: string | null;
  title: string;
  content: string | null;
  teaserImageUuid: string | null;
  author?: UserDto | null;
}
