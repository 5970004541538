/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';

export interface EntityModelQuestionnaireStatementAnswerDetailsDto {
  uuid?: string;
  tenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireQuestionUuid: string;
  questionnaireStatementUuid: string;
  questionnaireAnswerUuid: string;
  score?: number;
  links?: Array<Link>;
  excludeStatementFromScoring?: boolean;
}
