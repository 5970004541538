import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  AuthenticationService,
  ResetPasswordRequestDto,
  TokenResponseDto,
  VerifyByTokenRequestParams,
} from '../nestjs-backend';
import { JwtHelperService } from './jwt-helper.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private localStorageService = inject(LocalStorageService);
  private jwtHelperService = inject(JwtHelperService);
  private authenticationService = inject(AuthenticationService);

  private isLoading$$ = new BehaviorSubject<boolean>(true);
  private isRefreshing$$ = new BehaviorSubject<boolean>(false);
  private currentAuthentication$$: BehaviorSubject<TokenResponseDto | null>;

  private tenantSlug: string;

  constructor() {
    this.tenantSlug = this.extractTenantSlugFromHostname();
    this.currentAuthentication$$ = new BehaviorSubject<TokenResponseDto | null>(
      this.localStorageService.getAuthentication(),
    );
    this.isLoading$$.next(false);
  }

  get isLoading$() {
    return this.isLoading$$.asObservable();
  }

  get isRefreshing$() {
    return this.isRefreshing$$.asObservable();
  }

  get currentAuthentication$() {
    return this.currentAuthentication$$.asObservable();
  }

  get currentTenantSlug() {
    return this.tenantSlug;
  }

  get currentAccessTokenValue() {
    return this.currentAuthentication$$.value?.accessToken;
  }

  get currentRefreshTokenValue() {
    return this.currentAuthentication$$.value?.refreshToken;
  }

  get currentUserValue() {
    return this.currentAuthentication$$.value?.user;
  }

  get currentTenantValue() {
    return this.currentAuthentication$$.value?.user.tenant;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading$$.next(isLoading);
  }

  login(username: string, password: string) {
    return this.authenticationService
      .login({
        xTenantSlug: this.tenantSlug,
        loginRequestDto: { username, password },
      })
      .pipe(
        map((tokenResponseDto) => {
          if (tokenResponseDto) {
            this.localStorageService.setAuthentication(tokenResponseDto);
            this.currentAuthentication$$.next(tokenResponseDto);
          }
          return tokenResponseDto;
        }),
      );
  }

  refresh(): Observable<TokenResponseDto> {
    this.isRefreshing$$.next(true);
    return this.authenticationService
      .refresh({ xTenantSlug: this.tenantSlug })
      .pipe(
        tap((tokenResponseDto) => {
          if (tokenResponseDto) {
            this.localStorageService.setAuthentication(tokenResponseDto);
            this.currentAuthentication$$.next(tokenResponseDto);
          }
          this.isRefreshing$$.next(false);
        }),
      );
  }

  verifyByToken(uuid: string, token: string): Observable<TokenResponseDto> {
    const verifyByTokenRequestParams: VerifyByTokenRequestParams = {
      xTenantSlug: this.tenantSlug,
      userUuid: uuid,
      token,
    };
    return this.authenticationService
      .verifyByToken(verifyByTokenRequestParams)
      .pipe(
        tap((tokenResponseDto) => {
          if (tokenResponseDto) {
            this.localStorageService.setAuthentication(tokenResponseDto);
            this.currentAuthentication$$.next(tokenResponseDto);
          }
        }),
      );
  }

  verifySendMaiL(email: string): Observable<boolean> {
    return this.authenticationService
      .verifySendMail({ xTenantSlug: this.tenantSlug, email })
      .pipe(
        tap({
          next: () => {},
          error: (error) => {
            console.error('Error resending link:', error);
          },
        }),
        map(() => true),
        catchError(() => of(false)),
      );
  }

  logout() {
    this.authenticationService.logout({ xTenantSlug: this.tenantSlug });
    this.localStorageService.removeAuthentication();
    this.currentAuthentication$$.next(null);
    this.router.navigate(['/']);
  }

  isAuthenticated$(): Observable<boolean> {
    return this.currentAuthentication$.pipe(
      switchMap((authentication) => {
        if (
          !authentication?.accessToken ||
          !authentication.refreshToken ||
          this.jwtHelperService.isTokenExpired(authentication.refreshToken)
        ) {
          this.localStorageService.removeAuthentication();
          return of(false);
        }
        if (this.jwtHelperService.isTokenExpired(authentication.accessToken)) {
          return this.refresh().pipe(
            map((tokenResponseDto) => !!tokenResponseDto),
            catchError(() => of(false)),
          );
        }
        return of(true);
      }),
    );
  }

  isAdmin(): boolean {
    return this.currentAuthentication$$.value?.user.isAdmin || false;
  }

  sendPasswordResetMail(email: string): Observable<boolean> {
    return this.authenticationService
      .resetPasswordSendMail({ xTenantSlug: this.tenantSlug, email })
      .pipe(
        tap({
          next: () => {},
          error: (error) => {
            console.error(error);
            return throwError(() => error);
          },
        }),
        map(() => true),
        catchError((error) => {
          console.error('Error sending password reset mail:', error);
          return throwError(() => error);
        }),
      );
  }

  resetPasswordByToken(
    userUuid: string,
    token: string,
    password: string,
  ): Observable<boolean> {
    const resetPasswordRequestDto: ResetPasswordRequestDto = { password };
    return this.authenticationService
      .resetPasswordByToken({
        xTenantSlug: this.tenantSlug,
        userUuid,
        token,
        resetPasswordRequestDto,
      })
      .pipe(
        tap({
          next: () => {},
          error: (error) => {
            console.error(error);
            return throwError(() => error);
          },
        }),
        map(() => true),
        catchError((error) => {
          console.error('Error resetting password:', error);
          return throwError(() => error);
        }),
      );
  }

  private extractTenantSlugFromHostname(): string {
    if (!isPlatformBrowser(this.platformId)) {
      return '';
    }
    const hostname = window.location.hostname;
    return hostname.split('.')[0];
  }
}
