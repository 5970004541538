/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UpdateBlogEntryDto {
  publishedAt?: string;
  published?: boolean;
  authorUserUuid?: string | null;
  title?: string;
  content?: string | null;
}
