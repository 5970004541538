/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';
import { QuestionnaireAnswerDetailsDto } from './questionnaireAnswerDetailsDto';
import { QuestionnaireStatementDetailsDto } from './questionnaireStatementDetailsDto';
import { TranslationsDto } from './translationsDto';

export interface QuestionnaireQuestionDetailsDto {
  uuid?: string;
  tenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  type: QuestionnaireQuestionDetailsDto.TypeEnum;
  question?: TranslationsDto;
  description?: TranslationsDto;
  order?: number;
  weight?: number;
  answers?: Array<QuestionnaireAnswerDetailsDto>;
  statements?: Array<QuestionnaireStatementDetailsDto>;
  links?: Array<Link>;
  normalizeStatementWeights?: boolean;
}
export namespace QuestionnaireQuestionDetailsDto {
  export type TypeEnum = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE' | 'LICKERT_SCALE';
  export const TypeEnum = {
    SingleChoice: 'SINGLE_CHOICE' as TypeEnum,
    MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum,
    LickertScale: 'LICKERT_SCALE' as TypeEnum,
  };
}
