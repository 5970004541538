/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateGroupParentGroupRelationInputDto } from './createGroupParentGroupRelationInputDto';
import { CreateGroupUsersRelationInputDto } from './createGroupUsersRelationInputDto';

export interface CreateGroupDto {
  parentGroup?: CreateGroupParentGroupRelationInputDto | null;
  name: string;
  description?: string | null;
  users?: CreateGroupUsersRelationInputDto;
}
