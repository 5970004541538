/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupDto } from './groupDto';
import { PaginatedMeta } from './paginatedMeta';

export interface GetGroups200Response {
  data: Array<GroupDto>;
  meta: PaginatedMeta;
}
