<div
  *ngIf="userProfileImage; else noProfileImage"
  class="avatar hover:bg-base-300 flex items-center justify-center rounded-full"
>
  <div class="mx-auto rounded-full {{ circleClasses }}">
    <img
      [src]="userProfileImage"
      alt="{{ user?.firstName }} {{ user?.lastName }}"
    />
    <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
  </div>
</div>
<ng-template #noProfileImage>
  <div
    class="avatar placeholder hover:bg-base-300 flex items-center justify-center rounded-full"
  >
    <div
      class="mx-auto bg-neutral h-10 w-10 text-neutral-content rounded-full {{
        circleClasses
      }}"
    >
      <span class="{{ textClasses }}">
        {{ user?.firstName | slice: 0 : 1 }}{{ user?.lastName | slice: 0 : 1 }}
      </span>
      <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #uploadButton>
  <div
    *ngIf="hasUploadButton"
    class="absolute bottom-0 right-5 mt-auto h-3.5 w-3.5"
  >
    <button class="btn btn-circle btn-link btn-sm" (click)="fileInput?.click()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
        />
      </svg>
    </button>
    <input
      type="file"
      class="file-input file-input-bordered w-full max-w-xs"
      #fileInput
      hidden
      (change)="changeProfilePicture($event)"
    />
  </div>
</ng-template>
