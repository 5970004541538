export * from './assessmentAnswers.service';
export * from './assessmentCategories.service';
export * from './assessmentQuestions.service';
export * from './assessmentStages.service';
export * from './assessmentStatements.service';
export * from './assessments.service';
export * from './echo.service';
export * from './questionnaireAnswers.service';
export * from './questionnaireCategories.service';
export * from './questionnaireQuestions.service';
export * from './questionnaireStages.service';
export * from './questionnaireStatementAnswers.service';
export * from './questionnaireStatements.service';
export * from './questionnaires.service';
import { AssessmentAnswersService } from './assessmentAnswers.service';
import { AssessmentCategoriesService } from './assessmentCategories.service';
import { AssessmentQuestionsService } from './assessmentQuestions.service';
import { AssessmentStagesService } from './assessmentStages.service';
import { AssessmentStatementsService } from './assessmentStatements.service';
import { AssessmentsService } from './assessments.service';
import { EchoService } from './echo.service';
import { QuestionnaireAnswersService } from './questionnaireAnswers.service';
import { QuestionnaireCategoriesService } from './questionnaireCategories.service';
import { QuestionnaireQuestionsService } from './questionnaireQuestions.service';
import { QuestionnaireStagesService } from './questionnaireStages.service';
import { QuestionnaireStatementAnswersService } from './questionnaireStatementAnswers.service';
import { QuestionnaireStatementsService } from './questionnaireStatements.service';
import { QuestionnairesService } from './questionnaires.service';
export const APIS = [
  AssessmentAnswersService,
  AssessmentCategoriesService,
  AssessmentQuestionsService,
  AssessmentStagesService,
  AssessmentStatementsService,
  AssessmentsService,
  EchoService,
  QuestionnaireAnswersService,
  QuestionnaireCategoriesService,
  QuestionnaireQuestionsService,
  QuestionnaireStagesService,
  QuestionnaireStatementAnswersService,
  QuestionnaireStatementsService,
  QuestionnairesService,
];
