/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentCategoryDetailsDto } from './assessmentCategoryDetailsDto';
import { Link } from './link';
import { TranslationsDto } from './translationsDto';

export interface AssessmentStageDetailsDto {
  uuid?: string;
  tenantUuid: string;
  userUuid: string;
  assessmentUuid: string;
  parentAssessmentStageUuid?: string;
  questionnaireStageUuid: string;
  questionnaireStageName?: TranslationsDto;
  questionnaireStageOrder?: number;
  questionnaireStageWeight?: number;
  subject?: TranslationsDto;
  score?: number;
  progress?: number;
  categories?: Array<AssessmentCategoryDetailsDto>;
  links?: Array<Link>;
}
