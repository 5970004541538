/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UpdateUserProfileDto {
  email?: string;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  sector?: string | null;
  businessUnit?: string | null;
  country?: string | null;
  lang?: string | null;
  password?: string | null;
}
