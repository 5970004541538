/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { CreateGroupDto } from '../model/createGroupDto';
// @ts-ignore
import { GetGroups200Response } from '../model/getGroups200Response';
// @ts-ignore
import { GetUsers200Response } from '../model/getUsers200Response';
// @ts-ignore
import { GroupDto } from '../model/groupDto';
// @ts-ignore
import { UpdateGroupDto } from '../model/updateGroupDto';

// @ts-ignore
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

export interface CreateGroupRequestParams {
  xTenantSlug: string;
  createGroupDto: CreateGroupDto;
}

export interface DeleteGroupRequestParams {
  xTenantSlug: string;
  groupUuid: string;
}

export interface GetGroupRequestParams {
  xTenantSlug: string;
  groupUuid: string;
  include?: Array<'subGroups' | 'users'>;
}

export interface GetGroupUsersRequestParams {
  xTenantSlug: string;
  groupUuid: string;
  page?: number;
  size?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: 'createdAt' | 'email' | 'firstName' | 'lastName';
  status?: 'active' | 'pending' | 'inactive' | 'any';
  inheritUsersFromParentGroups?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface GetGroupsRequestParams {
  xTenantSlug: string;
  page?: number;
  size?: number;
  sortOrder?: 'asc' | 'desc';
  sortBy?: 'createdAt' | 'name';
  type?: 'root' | 'any';
  name?: string;
  parentGroupUuid?: string;
}

export interface UpdateGroupRequestParams {
  xTenantSlug: string;
  groupUuid: string;
  updateGroupDto: UpdateGroupDto;
}

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  protected basePath = 'http://localhost:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10),
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createGroup(
    requestParameters: CreateGroupRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GroupDto>;
  public createGroup(
    requestParameters: CreateGroupRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GroupDto>>;
  public createGroup(
    requestParameters: CreateGroupRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GroupDto>>;
  public createGroup(
    requestParameters: CreateGroupRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling createGroup.',
      );
    }
    const createGroupDto = requestParameters?.createGroupDto;
    if (createGroupDto === null || createGroupDto === undefined) {
      throw new Error(
        'Required parameter createGroupDto was null or undefined when calling createGroup.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/groups`;
    return this.httpClient.request<GroupDto>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: createGroupDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteGroup(
    requestParameters: DeleteGroupRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GroupDto>;
  public deleteGroup(
    requestParameters: DeleteGroupRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GroupDto>>;
  public deleteGroup(
    requestParameters: DeleteGroupRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GroupDto>>;
  public deleteGroup(
    requestParameters: DeleteGroupRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling deleteGroup.',
      );
    }
    const groupUuid = requestParameters?.groupUuid;
    if (groupUuid === null || groupUuid === undefined) {
      throw new Error(
        'Required parameter groupUuid was null or undefined when calling deleteGroup.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/groups/${this.configuration.encodeParam({ name: 'groupUuid', value: groupUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<GroupDto>(
      'delete',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGroup(
    requestParameters: GetGroupRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GroupDto>;
  public getGroup(
    requestParameters: GetGroupRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GroupDto>>;
  public getGroup(
    requestParameters: GetGroupRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GroupDto>>;
  public getGroup(
    requestParameters: GetGroupRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getGroup.',
      );
    }
    const groupUuid = requestParameters?.groupUuid;
    if (groupUuid === null || groupUuid === undefined) {
      throw new Error(
        'Required parameter groupUuid was null or undefined when calling getGroup.',
      );
    }
    const include = requestParameters?.include;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (include) {
      include.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(
          localVarQueryParameters,
          <any>element,
          'include',
        );
      });
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/groups/${this.configuration.encodeParam({ name: 'groupUuid', value: groupUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<GroupDto>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGroupUsers(
    requestParameters: GetGroupUsersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GetUsers200Response>;
  public getGroupUsers(
    requestParameters: GetGroupUsersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GetUsers200Response>>;
  public getGroupUsers(
    requestParameters: GetGroupUsersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GetUsers200Response>>;
  public getGroupUsers(
    requestParameters: GetGroupUsersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getGroupUsers.',
      );
    }
    const groupUuid = requestParameters?.groupUuid;
    if (groupUuid === null || groupUuid === undefined) {
      throw new Error(
        'Required parameter groupUuid was null or undefined when calling getGroupUsers.',
      );
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const sortOrder = requestParameters?.sortOrder;
    const sortBy = requestParameters?.sortBy;
    const status = requestParameters?.status;
    const inheritUsersFromParentGroups =
      requestParameters?.inheritUsersFromParentGroups;
    const email = requestParameters?.email;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page',
      );
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>size,
        'size',
      );
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortOrder,
        'sortOrder',
      );
    }
    if (sortBy !== undefined && sortBy !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortBy,
        'sortBy',
      );
    }
    if (status !== undefined && status !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>status,
        'status',
      );
    }
    if (
      inheritUsersFromParentGroups !== undefined &&
      inheritUsersFromParentGroups !== null
    ) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>inheritUsersFromParentGroups,
        'inheritUsersFromParentGroups',
      );
    }
    if (email !== undefined && email !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>email,
        'email',
      );
    }
    if (firstName !== undefined && firstName !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>firstName,
        'firstName',
      );
    }
    if (lastName !== undefined && lastName !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>lastName,
        'lastName',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/groups/${this.configuration.encodeParam({ name: 'groupUuid', value: groupUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/users`;
    return this.httpClient.request<GetUsers200Response>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getGroups(
    requestParameters: GetGroupsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GetGroups200Response>;
  public getGroups(
    requestParameters: GetGroupsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GetGroups200Response>>;
  public getGroups(
    requestParameters: GetGroupsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GetGroups200Response>>;
  public getGroups(
    requestParameters: GetGroupsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling getGroups.',
      );
    }
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const sortOrder = requestParameters?.sortOrder;
    const sortBy = requestParameters?.sortBy;
    const type = requestParameters?.type;
    const name = requestParameters?.name;
    const parentGroupUuid = requestParameters?.parentGroupUuid;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page',
      );
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>size,
        'size',
      );
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortOrder,
        'sortOrder',
      );
    }
    if (sortBy !== undefined && sortBy !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>sortBy,
        'sortBy',
      );
    }
    if (type !== undefined && type !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>type,
        'type',
      );
    }
    if (name !== undefined && name !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>name,
        'name',
      );
    }
    if (parentGroupUuid !== undefined && parentGroupUuid !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>parentGroupUuid,
        'parentGroupUuid',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/groups`;
    return this.httpClient.request<GetGroups200Response>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateGroup(
    requestParameters: UpdateGroupRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<GroupDto>;
  public updateGroup(
    requestParameters: UpdateGroupRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<GroupDto>>;
  public updateGroup(
    requestParameters: UpdateGroupRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<GroupDto>>;
  public updateGroup(
    requestParameters: UpdateGroupRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantSlug = requestParameters?.xTenantSlug;
    if (xTenantSlug === null || xTenantSlug === undefined) {
      throw new Error(
        'Required parameter xTenantSlug was null or undefined when calling updateGroup.',
      );
    }
    const groupUuid = requestParameters?.groupUuid;
    if (groupUuid === null || groupUuid === undefined) {
      throw new Error(
        'Required parameter groupUuid was null or undefined when calling updateGroup.',
      );
    }
    const updateGroupDto = requestParameters?.updateGroupDto;
    if (updateGroupDto === null || updateGroupDto === undefined) {
      throw new Error(
        'Required parameter updateGroupDto was null or undefined when calling updateGroup.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantSlug !== undefined && xTenantSlug !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-slug',
        String(xTenantSlug),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/groups/${this.configuration.encodeParam({ name: 'groupUuid', value: groupUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<GroupDto>(
      'patch',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: updateGroupDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }
}
