/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';
import { PageMetadata } from './pageMetadata';
import { QuestionnaireStageDto } from './questionnaireStageDto';

export interface PagedModelQuestionnaireStageDto {
  links?: Array<Link>;
  content?: Array<QuestionnaireStageDto>;
  page?: PageMetadata;
}
