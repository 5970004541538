/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BlogDto } from './blogDto';
import { PaginatedMeta } from './paginatedMeta';

export interface GetBlogs200Response {
  data: Array<BlogDto>;
  meta: PaginatedMeta;
}
