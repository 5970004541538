/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentBenchmarkCategoryDto } from './assessmentBenchmarkCategoryDto';
import { TranslationsDto } from './translationsDto';

export interface AssessmentBenchmarkStageDto {
  uuid?: string;
  name?: TranslationsDto;
  questionnaireStageAverageScore?: number;
  assessmentStageAverageScore?: number;
  categories?: Array<AssessmentBenchmarkCategoryDto>;
}
