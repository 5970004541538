<div class="drawer fixed z-50">
  <input #sidebarDrawer class="drawer-toggle" id="my-drawer" type="checkbox" />
  <div *ngIf="hasToggleButton" class="drawer-content flex-none">
    <div
      class="dropdown"
      (click)="toggleSidebar()"
      (keypress)="toggleSidebar()"
      tabindex="0"
    >
      <div tabindex="0" role="button" class="btn btn-circle btn-ghost">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="drawer-side">
    <label
      for="my-drawer"
      aria-label="close sidebar"
      class="drawer-overlay"
    ></label>
    <ul class="menu bg-base-200 text-base-content min-h-full w-80 p-4">
      <h5
        id="drawer-navigation-label"
        class="text-accent text-base font-semibold uppercase"
      >
        Menu
      </h5>
      <button
        type="button"
        data-drawer-hide="drawer-navigation"
        (click)="toggleSidebar()"
        aria-controls="drawer-navigation"
        class="absolute end-2.5 top-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          aria-hidden="true"
          class="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Close menu</span>
      </button>
      <div class="overflow-y-auto py-4">
        <li
          (click)="dashboardButtonClick()"
          (keypress)="dashboardButtonClick()"
          tabindex="0"
        >
          <a>{{ 'sidebar-dashboard-button' | transloco }}</a>
        </li>
        <li
          (click)="newsBlogButtonClick()"
          (keypress)="newsBlogButtonClick()"
          tabindex="1"
        >
          <a>{{ 'sidebar-news-blog-button' | transloco }}</a>
        </li>
        <li
          (click)="assessmentsButtonClick()"
          (keypress)="assessmentsButtonClick()"
          tabindex="1"
        >
          <a>{{ 'sidebar-assessments-button' | transloco }}</a>
        </li>
        <li
          (click)="userManagementButtonClick()"
          (keypress)="userManagementButtonClick()"
          tabindex="2"
        >
          <a>{{ 'sidebar-user-management-button' | transloco }}</a>
        </li>
      </div>
    </ul>
  </div>
</div>
