/**
 * INC Assessment Service
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { EntityModelQuestionnaireQuestionDetailsDto } from '../model/entityModelQuestionnaireQuestionDetailsDto';
// @ts-ignore
import { PagedModelQuestionnaireQuestionDto } from '../model/pagedModelQuestionnaireQuestionDto';
// @ts-ignore
import { QuestionnaireQuestionDto } from '../model/questionnaireQuestionDto';

// @ts-ignore
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

export interface CreateQuestionnaireQuestionRequestParams {
  xTenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireQuestionDto: QuestionnaireQuestionDto;
}

export interface DeleteQuestionnaireQuestionRequestParams {
  xTenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireQuestionUuid: string;
}

export interface GetQuestionnaireQuestionRequestParams {
  xTenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireQuestionUuid: string;
  language?: string;
}

export interface GetQuestionnaireQuestionsRequestParams {
  xTenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  language?: string;
  /** One-based page index (1..N) */
  page?: number;
  /** The size of the page to be returned */
  size?: number;
  /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: Array<string>;
}

export interface UpdateQuestionnaireQuestionRequestParams {
  xTenantUuid: string;
  questionnaireUuid: string;
  questionnaireStageUuid: string;
  questionnaireCategoryUuid: string;
  questionnaireQuestionUuid: string;
  questionnaireQuestionDto: QuestionnaireQuestionDto;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireQuestionsService {
  protected basePath = 'http://localhost:8420';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string,
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string,
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)),
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substring(0, 10),
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k,
            )),
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createQuestionnaireQuestion(
    requestParameters: CreateQuestionnaireQuestionRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<EntityModelQuestionnaireQuestionDetailsDto>;
  public createQuestionnaireQuestion(
    requestParameters: CreateQuestionnaireQuestionRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<EntityModelQuestionnaireQuestionDetailsDto>>;
  public createQuestionnaireQuestion(
    requestParameters: CreateQuestionnaireQuestionRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<EntityModelQuestionnaireQuestionDetailsDto>>;
  public createQuestionnaireQuestion(
    requestParameters: CreateQuestionnaireQuestionRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantUuid = requestParameters?.xTenantUuid;
    if (xTenantUuid === null || xTenantUuid === undefined) {
      throw new Error(
        'Required parameter xTenantUuid was null or undefined when calling createQuestionnaireQuestion.',
      );
    }
    const questionnaireUuid = requestParameters?.questionnaireUuid;
    if (questionnaireUuid === null || questionnaireUuid === undefined) {
      throw new Error(
        'Required parameter questionnaireUuid was null or undefined when calling createQuestionnaireQuestion.',
      );
    }
    const questionnaireStageUuid = requestParameters?.questionnaireStageUuid;
    if (
      questionnaireStageUuid === null ||
      questionnaireStageUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireStageUuid was null or undefined when calling createQuestionnaireQuestion.',
      );
    }
    const questionnaireCategoryUuid =
      requestParameters?.questionnaireCategoryUuid;
    if (
      questionnaireCategoryUuid === null ||
      questionnaireCategoryUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireCategoryUuid was null or undefined when calling createQuestionnaireQuestion.',
      );
    }
    const questionnaireQuestionDto =
      requestParameters?.questionnaireQuestionDto;
    if (
      questionnaireQuestionDto === null ||
      questionnaireQuestionDto === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireQuestionDto was null or undefined when calling createQuestionnaireQuestion.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantUuid !== undefined && xTenantUuid !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-uuid',
        String(xTenantUuid),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer-key) required
    localVarCredential = this.configuration.lookupCredential('bearer-key');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({ name: 'questionnaireUuid', value: questionnaireUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/stages/${this.configuration.encodeParam({ name: 'questionnaireStageUuid', value: questionnaireStageUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/categories/${this.configuration.encodeParam({ name: 'questionnaireCategoryUuid', value: questionnaireCategoryUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/questions`;
    return this.httpClient.request<EntityModelQuestionnaireQuestionDetailsDto>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: questionnaireQuestionDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteQuestionnaireQuestion(
    requestParameters: DeleteQuestionnaireQuestionRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<string>;
  public deleteQuestionnaireQuestion(
    requestParameters: DeleteQuestionnaireQuestionRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<string>>;
  public deleteQuestionnaireQuestion(
    requestParameters: DeleteQuestionnaireQuestionRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<string>>;
  public deleteQuestionnaireQuestion(
    requestParameters: DeleteQuestionnaireQuestionRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantUuid = requestParameters?.xTenantUuid;
    if (xTenantUuid === null || xTenantUuid === undefined) {
      throw new Error(
        'Required parameter xTenantUuid was null or undefined when calling deleteQuestionnaireQuestion.',
      );
    }
    const questionnaireUuid = requestParameters?.questionnaireUuid;
    if (questionnaireUuid === null || questionnaireUuid === undefined) {
      throw new Error(
        'Required parameter questionnaireUuid was null or undefined when calling deleteQuestionnaireQuestion.',
      );
    }
    const questionnaireStageUuid = requestParameters?.questionnaireStageUuid;
    if (
      questionnaireStageUuid === null ||
      questionnaireStageUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireStageUuid was null or undefined when calling deleteQuestionnaireQuestion.',
      );
    }
    const questionnaireCategoryUuid =
      requestParameters?.questionnaireCategoryUuid;
    if (
      questionnaireCategoryUuid === null ||
      questionnaireCategoryUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireCategoryUuid was null or undefined when calling deleteQuestionnaireQuestion.',
      );
    }
    const questionnaireQuestionUuid =
      requestParameters?.questionnaireQuestionUuid;
    if (
      questionnaireQuestionUuid === null ||
      questionnaireQuestionUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireQuestionUuid was null or undefined when calling deleteQuestionnaireQuestion.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantUuid !== undefined && xTenantUuid !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-uuid',
        String(xTenantUuid),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer-key) required
    localVarCredential = this.configuration.lookupCredential('bearer-key');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({ name: 'questionnaireUuid', value: questionnaireUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/stages/${this.configuration.encodeParam({ name: 'questionnaireStageUuid', value: questionnaireStageUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/categories/${this.configuration.encodeParam({ name: 'questionnaireCategoryUuid', value: questionnaireCategoryUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/questions/${this.configuration.encodeParam({ name: 'questionnaireQuestionUuid', value: questionnaireQuestionUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<string>(
      'delete',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuestionnaireQuestion(
    requestParameters: GetQuestionnaireQuestionRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<EntityModelQuestionnaireQuestionDetailsDto>;
  public getQuestionnaireQuestion(
    requestParameters: GetQuestionnaireQuestionRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<EntityModelQuestionnaireQuestionDetailsDto>>;
  public getQuestionnaireQuestion(
    requestParameters: GetQuestionnaireQuestionRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<EntityModelQuestionnaireQuestionDetailsDto>>;
  public getQuestionnaireQuestion(
    requestParameters: GetQuestionnaireQuestionRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantUuid = requestParameters?.xTenantUuid;
    if (xTenantUuid === null || xTenantUuid === undefined) {
      throw new Error(
        'Required parameter xTenantUuid was null or undefined when calling getQuestionnaireQuestion.',
      );
    }
    const questionnaireUuid = requestParameters?.questionnaireUuid;
    if (questionnaireUuid === null || questionnaireUuid === undefined) {
      throw new Error(
        'Required parameter questionnaireUuid was null or undefined when calling getQuestionnaireQuestion.',
      );
    }
    const questionnaireStageUuid = requestParameters?.questionnaireStageUuid;
    if (
      questionnaireStageUuid === null ||
      questionnaireStageUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireStageUuid was null or undefined when calling getQuestionnaireQuestion.',
      );
    }
    const questionnaireCategoryUuid =
      requestParameters?.questionnaireCategoryUuid;
    if (
      questionnaireCategoryUuid === null ||
      questionnaireCategoryUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireCategoryUuid was null or undefined when calling getQuestionnaireQuestion.',
      );
    }
    const questionnaireQuestionUuid =
      requestParameters?.questionnaireQuestionUuid;
    if (
      questionnaireQuestionUuid === null ||
      questionnaireQuestionUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireQuestionUuid was null or undefined when calling getQuestionnaireQuestion.',
      );
    }
    const language = requestParameters?.language;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (language !== undefined && language !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>language,
        'language',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantUuid !== undefined && xTenantUuid !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-uuid',
        String(xTenantUuid),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer-key) required
    localVarCredential = this.configuration.lookupCredential('bearer-key');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({ name: 'questionnaireUuid', value: questionnaireUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/stages/${this.configuration.encodeParam({ name: 'questionnaireStageUuid', value: questionnaireStageUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/categories/${this.configuration.encodeParam({ name: 'questionnaireCategoryUuid', value: questionnaireCategoryUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/questions/${this.configuration.encodeParam({ name: 'questionnaireQuestionUuid', value: questionnaireQuestionUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<EntityModelQuestionnaireQuestionDetailsDto>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuestionnaireQuestions(
    requestParameters: GetQuestionnaireQuestionsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<PagedModelQuestionnaireQuestionDto>;
  public getQuestionnaireQuestions(
    requestParameters: GetQuestionnaireQuestionsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<PagedModelQuestionnaireQuestionDto>>;
  public getQuestionnaireQuestions(
    requestParameters: GetQuestionnaireQuestionsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<PagedModelQuestionnaireQuestionDto>>;
  public getQuestionnaireQuestions(
    requestParameters: GetQuestionnaireQuestionsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantUuid = requestParameters?.xTenantUuid;
    if (xTenantUuid === null || xTenantUuid === undefined) {
      throw new Error(
        'Required parameter xTenantUuid was null or undefined when calling getQuestionnaireQuestions.',
      );
    }
    const questionnaireUuid = requestParameters?.questionnaireUuid;
    if (questionnaireUuid === null || questionnaireUuid === undefined) {
      throw new Error(
        'Required parameter questionnaireUuid was null or undefined when calling getQuestionnaireQuestions.',
      );
    }
    const questionnaireStageUuid = requestParameters?.questionnaireStageUuid;
    if (
      questionnaireStageUuid === null ||
      questionnaireStageUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireStageUuid was null or undefined when calling getQuestionnaireQuestions.',
      );
    }
    const questionnaireCategoryUuid =
      requestParameters?.questionnaireCategoryUuid;
    if (
      questionnaireCategoryUuid === null ||
      questionnaireCategoryUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireCategoryUuid was null or undefined when calling getQuestionnaireQuestions.',
      );
    }
    const language = requestParameters?.language;
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const sort = requestParameters?.sort;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (language !== undefined && language !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>language,
        'language',
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page',
      );
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>size,
        'size',
      );
    }
    if (sort) {
      sort.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(
          localVarQueryParameters,
          <any>element,
          'sort',
        );
      });
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantUuid !== undefined && xTenantUuid !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-uuid',
        String(xTenantUuid),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer-key) required
    localVarCredential = this.configuration.lookupCredential('bearer-key');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({ name: 'questionnaireUuid', value: questionnaireUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/stages/${this.configuration.encodeParam({ name: 'questionnaireStageUuid', value: questionnaireStageUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/categories/${this.configuration.encodeParam({ name: 'questionnaireCategoryUuid', value: questionnaireCategoryUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/questions`;
    return this.httpClient.request<PagedModelQuestionnaireQuestionDto>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateQuestionnaireQuestion(
    requestParameters: UpdateQuestionnaireQuestionRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<EntityModelQuestionnaireQuestionDetailsDto>;
  public updateQuestionnaireQuestion(
    requestParameters: UpdateQuestionnaireQuestionRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpResponse<EntityModelQuestionnaireQuestionDetailsDto>>;
  public updateQuestionnaireQuestion(
    requestParameters: UpdateQuestionnaireQuestionRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<HttpEvent<EntityModelQuestionnaireQuestionDetailsDto>>;
  public updateQuestionnaireQuestion(
    requestParameters: UpdateQuestionnaireQuestionRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: {
      httpHeaderAccept?: 'application/json';
      context?: HttpContext;
      transferCache?: boolean;
    },
  ): Observable<any> {
    const xTenantUuid = requestParameters?.xTenantUuid;
    if (xTenantUuid === null || xTenantUuid === undefined) {
      throw new Error(
        'Required parameter xTenantUuid was null or undefined when calling updateQuestionnaireQuestion.',
      );
    }
    const questionnaireUuid = requestParameters?.questionnaireUuid;
    if (questionnaireUuid === null || questionnaireUuid === undefined) {
      throw new Error(
        'Required parameter questionnaireUuid was null or undefined when calling updateQuestionnaireQuestion.',
      );
    }
    const questionnaireStageUuid = requestParameters?.questionnaireStageUuid;
    if (
      questionnaireStageUuid === null ||
      questionnaireStageUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireStageUuid was null or undefined when calling updateQuestionnaireQuestion.',
      );
    }
    const questionnaireCategoryUuid =
      requestParameters?.questionnaireCategoryUuid;
    if (
      questionnaireCategoryUuid === null ||
      questionnaireCategoryUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireCategoryUuid was null or undefined when calling updateQuestionnaireQuestion.',
      );
    }
    const questionnaireQuestionUuid =
      requestParameters?.questionnaireQuestionUuid;
    if (
      questionnaireQuestionUuid === null ||
      questionnaireQuestionUuid === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireQuestionUuid was null or undefined when calling updateQuestionnaireQuestion.',
      );
    }
    const questionnaireQuestionDto =
      requestParameters?.questionnaireQuestionDto;
    if (
      questionnaireQuestionDto === null ||
      questionnaireQuestionDto === undefined
    ) {
      throw new Error(
        'Required parameter questionnaireQuestionDto was null or undefined when calling updateQuestionnaireQuestion.',
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (xTenantUuid !== undefined && xTenantUuid !== null) {
      localVarHeaders = localVarHeaders.set(
        'x-tenant-uuid',
        String(xTenantUuid),
      );
    }

    let localVarCredential: string | undefined;
    // authentication (bearer-key) required
    localVarCredential = this.configuration.lookupCredential('bearer-key');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential,
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected,
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined =
      options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected,
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({ name: 'questionnaireUuid', value: questionnaireUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/stages/${this.configuration.encodeParam({ name: 'questionnaireStageUuid', value: questionnaireStageUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/categories/${this.configuration.encodeParam({ name: 'questionnaireCategoryUuid', value: questionnaireCategoryUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/questions/${this.configuration.encodeParam({ name: 'questionnaireQuestionUuid', value: questionnaireQuestionUuid, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<EntityModelQuestionnaireQuestionDetailsDto>(
      'patch',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: questionnaireQuestionDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress,
      },
    );
  }
}
