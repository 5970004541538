/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UpdateGroupParentGroupRelationInputDto } from './updateGroupParentGroupRelationInputDto';
import { UpdateGroupUsersRelationInputDto } from './updateGroupUsersRelationInputDto';

export interface UpdateGroupDto {
  parentGroup?: UpdateGroupParentGroupRelationInputDto | null;
  name?: string;
  description?: string | null;
  users?: UpdateGroupUsersRelationInputDto;
}
