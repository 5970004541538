/**
 * Blueprint Application NestJS Backend
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BlogEntryDto } from './blogEntryDto';
import { PaginatedMeta } from './paginatedMeta';

export interface GetBlogEntries200Response {
  data: Array<BlogEntryDto>;
  meta: PaginatedMeta;
}
